import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Link from "next/link";

import { Background, Nav } from "../components/layout";
import { selectUserState, setUserState } from "../slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import Errors from "../components/form-ui/Errors";
import LanderOgMetaData from "../components/openGraph/LanderOgMetaData";
import { useCookies } from "react-cookie";
import { UserService } from "../services/userService";
import { MuiRouter } from "../components/interfaces";
import { MERCHANT_UI_BASE_URL } from "../constants";

export default function MerchantLogin() {
  const { t } = useTranslation();
  const router = useRouter() as MuiRouter;
  const dispatch = useDispatch();
  const userState = useSelector(selectUserState);
  const userService = new UserService(null);
  const [cookies] = useCookies();

  const MERCHANT_EMAIL_INPUT_ID = "email";
  const MERCHANT_PASSWORD_INPUT_ID = "password";

  const [loginPayload, setLoginPayload] = useState({
    [MERCHANT_EMAIL_INPUT_ID]: "",
    [MERCHANT_PASSWORD_INPUT_ID]: "",
  });
  const [loginErrors, setLoginErrors] = useState([] as string[]);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await userService.login(loginPayload);
      const { token } = response;
      console.log(response)
      if (token) {
        dispatch(
          setUserState({
            ...userState,
            userDetails: token,
            isAuthenticated: cookies.authenticated,
          })
        );
        router.push(`${MERCHANT_UI_BASE_URL}/forms?session=${token}`);
      }
    } catch (ex) {
      if (ex instanceof Error) { //TODO: This should be based on response message.
        setLoginErrors(["Your email or password is wrong. Please check and try again."]);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginPayload({ ...loginPayload, [e.target.id]: e.target.value });
  };

  return (
    <>
      <LanderOgMetaData urlSlug={router.pathname} />
      <Background classes="background--md">
        <>
          <Nav hideNavItems />
          <div className="body-container-center-wrapper">
            <div className="container-sm login">
              <h3 className="login__heading">{t("Welcome")}</h3>
              <form id="login-form" onSubmit={handleLogin}>
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor={MERCHANT_EMAIL_INPUT_ID}
                  >
                    {t("Email")}
                  </label>
                  <input
                    type="email"
                    id={MERCHANT_EMAIL_INPUT_ID}
                    className="form-control"
                    placeholder={t("Your email")}
                    onChange={handleChange}
                    value={loginPayload[MERCHANT_EMAIL_INPUT_ID]}
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor={MERCHANT_PASSWORD_INPUT_ID}
                  >
                    {t("Password")}
                  </label>
                  <input
                    type="password"
                    id={MERCHANT_PASSWORD_INPUT_ID}
                    className="form-control"
                    onChange={handleChange}
                    value={loginPayload[MERCHANT_PASSWORD_INPUT_ID]}
                  />
                  <p className="mt-2">
                    <small className="text-muted">
                      <a href={`${MERCHANT_UI_BASE_URL}/forgot-password`}>
                        {t("Forgot password?")}
                      </a>
                    </small>
                  </p>
                </div>
                <button
                  type="submit"
                  form="login-form"
                  className="btn btn-primary btn-block"
                >
                  {t("Login")}
                </button>
              </form>
              {loginErrors.length > 0 && (
                <Errors
                  message={loginErrors[0]}
                  // TODO: consider making `validations` and `parentBlockId` optional, or use a different Errors component for the login page.
                  validations={[]}
                  parentBlockId=""
                />
              )}
              <p className="login__signup">
                <small className="text-muted">
                  {t("New to FormPay?")}{" "}
                  <Link href={new URL(`${MERCHANT_UI_BASE_URL}/signup`)}>
                    {t("Get started")}
                  </Link>
                </small>
              </p>
            </div>
          </div>
        </>
      </Background>
    </>
  );
}
